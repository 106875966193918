<template>
  <div>
    <a-config-provider :locale="locale">
      <div id="app">
        <router-view/>
      </div>
    </a-config-provider>
    <!-- 将上传组件全局注册 -->
    <global-uploader />
  </div>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import GlobalUploader from '@/components/vue-simple-uploader/GlobalUploader'
export default {
  data () {
    return {
    }
  },
  components: {
    GlobalUploader
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
