import request from '@/utils/request'

const commonApi = {
  GetUploadRequiredInfo: '/common/getUploadRequiredInfo',
  ApplyTmpCredential: '/common/applyTmpCredential'
}

export const applyTmpCredentialApi = process.env.VUE_APP_API_BASE_URL + commonApi.ApplyTmpCredential

export function getUploadRequiredInfo () {
  return request({
    url: commonApi.GetUploadRequiredInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
