// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/content/',
    children: [
      // dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: RouteView,
        meta: { title: '信息概览', keepAlive: true, icon: 'dashboard', permission: ['dashboard1'] },
        children: [
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: '概览', keepAlive: true,}
          },
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/dashboard/Analysis'),
            meta: { title: 'menu.dashboard.analysis', keepAlive: false, permission: ['dashboard'] }
          }
          // 外部链接
          // {
          //   path: 'https://www.baidu.com/',
          //   name: 'Monitor',
          //   meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          // },

        ]
      },
      // 内容管理
      {
        path: '/content',
        redirect: '/content/video/',
        component: RouteView,
        meta: { title: '视频管理', icon: 'appstore', permission: ['videoMgmt'] },
        children: [
          {
            path: 'video',
            name: '视频',
            component: () => import('../views/content/video'),
            meta: { title: '视频', keepAlive: true}
          },
          {
            path: 'stream',
            name: '直播流',
            component: () => import('../views/content/stream'),
            meta: { title: '直播流', }
          },
          {
            path: 'videoSet',
            name: '视频集',
            component: () => import('../views/content/videoSet'),
            meta: { title: '视频集',  }
          },
          {
            path: 'issueDevice',
            name: '下发终端',
            hidden: true,
            component: () => import('../views/content/issueDevice'),
            meta: { title: '下发终端', keepAlive: false,  }
          },
          {
            path: 'videoSetManage',
            name: '视频集- 视频管理',
            hidden: true,
            component: () => import('../views/content/videoSetManage'),
            meta: { title: '视频集- 视频管理', keepAlive: false,  }
          }
        ]
      },
     // 播单管理
     {
       path: '/playlist',
       redirect: '/playlist/list',
       component: RouteView,
       meta: { title: '播单管理', keepAlive: true, icon: 'play-square' ,permission: ['playScheduleMgmt']},
       children: [
         {
           path: '/playlist/list',
           name: '播单列表',
           component: () => import('../views/playlist/list'),
           meta: { title: '播单列表', keepAlive: false,  }
         },
         {
           path: '/playlist/videos',
           name: '播单编排',
           hidden: true,
           component: () => import('../views/playlist/videos'),
           meta: { title: '播单编排', keepAlive: false }
         },
         {
           path: '/playlist/dispatch',
           name: '播单下发',
           hidden: true,
           component: () => import('../views/playlist/dispatch'),
           meta: { title: '播单下发', keepAlive: false }
         },
         // {
         //   path: '/playlist/recycle_bin',
         //   name: '回收站',
         //   component: () => import('../views/playlist/recycle_bin'),
         //   meta: { title: '回收站', keepAlive: false, icon: 'delete' }
         // }
       ]
     },
      // 设备管理
      {
        path: '/device',
        redirect: '/device/devicelist',
        component: RouteView,
        meta: { title: '终端管理', keepAlive: true, icon: 'code-sandbox' ,permission: ['deviceMgmt']},
        children: [
          {
            path: 'devicelist',
            name: '终端列表',
            component: () => import('../views/device/list'),
            meta: { title: '终端列表', keepAlive: false,  }
          },
          
          {
            path: 'detail',
            name: '终端详情',
            hidden: true,
            component: () => import('../views/device/detail'),
            meta: { title: '终端详情', keepAlive: false,  }
          },
          {
            path: 'control',
            name: '终端播控',
            hidden: true,
            component: () => import('../views/device/control'),
            meta: { title: '终端播控', keepAlive: false,  }
          },
        ]
      },
      // 视频通话管理
      {
        path: '/room',
        redirect: '/room/room_list/',
        component: RouteView,
        meta: { title: '视频通话管理', icon: 'appstore' ,permission: ['rtcMgmt']},
        children: [
          {
            path: 'room_list',
            name: '实时通信管理',
            component: () => import('../views/room/room_list'),
            meta: { title: '实时通信管理',  }
          },

          {
            path: 'room_record_list',
            name: '实时通信录制管理',
            hidden: true,
            component: () => import('../views/room/room_record_list'),
            meta: { title: '实时通信录制管理', keepAlive: false,  }
          }
        ]
      },
      // 应用管理
      {
        path: '/app',
        redirect: '/app/applist/',
        component: RouteView,
        meta: { title: '应用管理', icon: 'appstore' ,permission: ['appMgmt']},
        children: [
          {
            path: 'applist',
            name: '应用管理',
            component: () => import('@/views/app/app_list'),
            meta: { title: '应用管理',  }
          }
        ]
      },
      // 操作员管理
      {
        path: '/operator',
        redirect: '/operator/operatorList/',
        component: RouteView,
        meta: { title: '操作员管理', icon: 'team' ,permission: ['operatorMgmt']},
        children: [
          {
            path: 'operatorList',
            name: '操作员列表',
            component: () => import('@/views/operator/operatorList'),
            meta: { title: '操作员列表',  }
          },
        ]
      },
      // 租户管理员
      {
        path: '/tenement',
        redirect: '/tenement/list/',
        component: RouteView,
        meta: { title: '租户管理', icon: 'team' ,permission: ['managerMgmt']},
        children: [
          {
            path: 'list',
            name: '租户管理员',
            component: () => import('@/views/tenement/tenementList'),
            meta: { title: '租户管理员',  }
          },
        ]
      },
      {
        path: '/manage',
        component: RouteView,
        redirect: '/manage/group_list',
        name: '系统管理',
        meta: { title: '系统管理', icon: 'setting', keepAlive: true, permission: ['systemMgmt'] },
        children: [
          {
            path: 'group_list',
            name: '终端分组',
            component: () => import('../views/device/group_list'),
            meta: { title: '终端分组', keepAlive: false }
          },
          {
            path: 'groupManage',
            name: '终端组管理',
            hidden: true,
            component: () => import('../views/device/groupManage'),
            meta: { title: '终端组管理', keepAlive: false }
          },
        ]
      },
      {
        path: '/upgrade',
        component: RouteView,
        redirect: '/upgrade/upgradeManage',
        name: '升级包管理',
        meta: { title: '升级包管理', icon: 'setting', keepAlive: true, permission: ['upgradePackageMgmt'] },
        children: [
          {
            path: 'upgradeManage',
            name: '升级包管理',
            component: () => import('@/views/manage/upgradeManage'),
            meta: { title: '升级包管理', keepAlive: true }
          },
        ]
      },
      // account
      // {
      //   path: '/account',
      //   component: RouteView,
      //   redirect: '/account/center',
      //   name: '系统管理',
      //   meta: { title: '系统管理', icon: 'setting', keepAlive: true, permission: ['account'] },
      //   children: [
      //     {
      //       path: '/account/center',
      //       name: '账号信息',
      //       component: () => import('@/views/account/center'),
      //       meta: { title: '账号信息', keepAlive: true, permission: ['user'] }
      //     },
          // {
          //   path: '/account/settings',
          //   name: '设置中心',
          //   component: () => import('@/views/account/settings/Index'),
          //   meta: { title: '设置中心', hideHeader: true, permission: ['user'] },
          //   redirect: '/account/settings/basic',
          //   hideChildrenInMenu: true,
          //   children: [
          //     {
          //       path: '/account/settings/basic',
          //       name: 'BasicSettings',
          //       component: () => import('@/views/account/settings/BasicSetting'),
          //       meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['user'] }
          //     },
          //     {
          //       path: '/account/settings/security',
          //       name: 'SecuritySettings',
          //       component: () => import('@/views/account/settings/Security'),
          //       meta: {
          //         title: 'account.settings.menuMap.security',
          //         hidden: true,
          //         keepAlive: true,
          //         permission: ['user']
          //       }
          //     },
          //     {
          //       path: '/account/settings/custom',
          //       name: 'CustomSettings',
          //       component: () => import('@/views/account/settings/Custom'),
          //       meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] }
          //     },
          //     {
          //       path: '/account/settings/binding',
          //       name: 'BindingSettings',
          //       component: () => import('@/views/account/settings/Binding'),
          //       meta: { title: 'account.settings.menuMap.binding', hidden: true, keepAlive: true, permission: ['user'] }
          //     },
          //     {
          //       path: '/account/settings/notification',
          //       name: 'NotificationSettings',
          //       component: () => import('@/views/account/settings/Notification'),
          //       meta: {
          //         title: 'account.settings.menuMap.notification',
          //         hidden: true,
          //         keepAlive: true,
          //         permission: ['user']
          //       }
          //     }
          //   ]
          // },
          // {
          //   path: '/profile/basic',
          //   name: 'ProfileBasic',
          //   component: () => import('@/views/profile/basic'),
          //   meta: { title: 'menu.profile.basic', permission: ['profile'] }
          // },
          // {
          //   path: '/profile/advanced',
          //   name: 'ProfileAdvanced',
          //   component: () => import('@/views/profile/advanced/Advanced'),
          //   meta: { title: 'menu.profile.advanced', permission: ['profile'] }
          // }
      //   ]
      // },

      // other
      // {
      //   path: '/other',
      //   name: 'otherPage',
      //   component: RouteView,
      //   meta: { title: '其他组件', icon: 'slack', permission: [ 'dashboard' ] },
      //   redirect: '/other/icon-selector',
      //   children: [
      //     {
      //       path: '/other/icon-selector',
      //       name: 'TestIconSelect',
      //       component: () => import('@/views/other/IconSelectorView'),
      //       meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: [ 'dashboard' ] }
      //     },
      //     {
      //       path: '/other/list',
      //       component: RouteView,
      //       meta: { title: '业务布局', icon: 'layout', permission: [ 'support' ] },
      //       redirect: '/other/list/tree-list',
      //       children: [
      //         {
      //           path: '/other/list/tree-list',
      //           name: 'TreeList',
      //           component: () => import('@/views/other/TreeList'),
      //           meta: { title: '树目录表格', keepAlive: true }
      //         },
      //         {
      //           path: '/other/list/edit-table',
      //           name: 'EditList',
      //           component: () => import('@/views/other/TableInnerEditList'),
      //           meta: { title: '内联编辑表格', keepAlive: true }
      //         },
      //         {
      //           path: '/other/list/user-list',
      //           name: 'UserList',
      //           component: () => import('@/views/other/UserList'),
      //           meta: { title: '用户列表', keepAlive: true }
      //         },
      //         {
      //           path: '/other/list/role-list',
      //           name: 'RoleList',
      //           component: () => import('@/views/other/RoleList'),
      //           meta: { title: '角色列表', keepAlive: true }
      //         },
      //         {
      //           path: '/other/list/system-role',
      //           name: 'SystemRole',
      //           component: () => import('@/views/other/RoleList'),
      //           meta: { title: '角色列表2', keepAlive: true }
      //         },
      //         {
      //           path: '/other/list/permission-list',
      //           name: 'PermissionList',
      //           component: () => import('@/views/other/PermissionList'),
      //           meta: { title: '权限列表', keepAlive: true }
      //         }
      //       ]
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
