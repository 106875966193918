<template>
  <div id="global-uploader" :class="{'global-uploader-single': !global}">
    <!-- 上传 -->

    <div v-show="panelShow">
      <div class="file-panel" :class="{ collapse: collapse }">
        <div class="file-title">
          <div class="title">文件列表</div>
          <div class="operate">
            <Button @click="collapse = !collapse" type="text" icon="md-swap" :title="collapse ? '展开' : '折叠'">

            </Button>
            <!-- <Button @click="close" type="text" icon="md-close" title="关闭">
            </Button> -->
          </div>
        </div>
        <a-upload ref="uploader" name="file" v-model:file-list="uploadedFileList" :multiple="true" :maxCount="3"
         @change="handleChange" :accept="acceptType" :customRequest="cosUpload" :remove="deleteFileItem"
          class="uploader-app">
          <a-button id="global-uploader-btn" ref="uploadBtn">选择文件</a-button>
        </a-upload>
        <ul class="file-list">
          <li class="file-item" v-for="(file,index) in uploadedFileList" :key="file.uid">
           
          </li>
          <div class="no-file" v-if="!uploadedFileList.length">
            <i class="iconfont icon-empty-file"></i> 暂无待上传文件
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   *  全局上传插件，两种调用方式
   *   1. 作为全局页面的组件，使用event bus
   *   调用方法：Bus.$emit('openUploader', {params: {}, options: {}})
   *               params: 发送给服务器的额外参数；
   *               options：上传选项，目前支持 target、testChunks、mergeFn、accept
   *
   *   监听函数：Bus.$on('fileAdded', fn); 文件选择后的回调
   *           Bus.$on('fileSuccess', fn); 文件上传成功的回调，监听后记得释放
   *
   *   2. 作为普通组件在单个页面中调用，使用props
   */
  import {
    ACCEPT_CONFIG
  } from './js/config'
  import Bus from './js/bus'
  import SparkMD5 from 'spark-md5'
  import {
    getUploadRequiredInfo,
    applyTmpCredentialApi
  } from '@/api/common'
  import {
    ACCESS_TOKEN
  } from '@/store/mutation-types'
  import storage from 'store'

  import {
    addVideo
  } from '@/api/content'
  var COS = require('cos-js-sdk-v5')

  export default {
    props: {
      global: {
        type: Boolean,
        default: true
      },
      params: {
        type: Object
      },
      options: {
        type: Object
      }
    },

    data() {
      return {

        fileStatusText: {
          success: '上传成功',
          error: '上传失败',
          uploading: '上传中',
          paused: '已暂停',
          waiting: '等待上传'
        },
        panelShow: false, //选择文件后，展示上传panel
        collapse: false,
        customParams: {},
        customStatus: '',
        uploadedFileList: [],
        acceptType: ""
      }
    },

    watch: {
      uploadedFileList: {
        handler(data) {
          // this.panelShow = data.length > 0
        },
        immediate: true
      },

    },

    mounted() {
      var that = this;
      Bus.$on('openUploader', ({
        params = {},
        options = {}
      }) => {
        this.uploadedFileList = []
        this.customParams = params;
        this.customizeOptions(options)
        //延迟300毫秒执行  
        setTimeout(function() {
          if (that.$refs.uploadBtn) {
            that.$refs.uploadBtn.$el.click()
          }
        }, 100);
      });
    },

    computed: {
      // Uploader实例
      uploader() {
        return this.$refs.uploader.uploader
      }
    },

    methods: {
      getCOS() {
        return new COS({
          // getAuthorization 必选参数
          getAuthorization: function(options, callback) {
            // 初始化时不会调用，只有调用cos方法（比如cos.putObject）时才会进入
            // 异步获取临时密钥
            // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
            // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
            // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048

            const token = storage.get(ACCESS_TOKEN)
            var url = applyTmpCredentialApi + '?region=' + options.Region + '&bucket=' + options.Bucket +
              '&key=' + (options.Key || options.Query.prefix)

            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.setRequestHeader(ACCESS_TOKEN, token)
            xhr.onload = function(e) {
              var data = JSON.parse(xhr.responseText)
              var credentials = data.data.credentials
              if (!data || !credentials) {
                return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
              }
              var callbackData = {
                TmpSecretId: credentials.tmpSecretId, // 可传固定密钥或者临时密钥
                TmpSecretKey: credentials.tmpSecretKey, // 可传固定密钥或者临时密钥
                SecurityToken: credentials.sessionToken,
                // 使用服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
                ExpiredTime: data.data.expiredTime // 时间戳，单位秒，如：1580000000
              }
              callback(callbackData)
            }
            xhr.send()
          }
        })
      },
      cosUpload({
        action,
        file,
        onSuccess,
        onError,
        onProgress
      }) {
        if (!file) {
          this.$notification.error({
            message: '请选择文件',
            description: '请选择文件进行上传'
          })
          return
        }
        var that = this;
        this.panelShow = true;
        //初始化文件信息
        const fileInfo = {
          status: "uploading",
          response: "",
          url: "",
          ...file
        };
        //放入上传列表中，以便于显示上传进度
        this.uploadedFileList.push(fileInfo);
        this.computeMD5(file).then((result) => {
          this.startUpload({...result, onSuccess,
        onError,
        onProgress})
        }).catch(err=>{
           
        })
        
      },
      handleChange(info) {
        const status = info.file.status
        if (status !== 'uploading') {
          console.log(info.file, info.fileList)
        }

        if (status === 'done') {
          this.$message.success(`${info.file.name} 上传成功.`)
          this.deleteFileItem(info.file)
        } else if (status === 'error') {
          
          this.deleteFileItem(info.file)
          this.$message.error(`${info.file.name} 上传失败`)
        }
      },
      //删除文件记录条目
      deleteFileItem(file) {
        //找到当前文件所在列表的索引
        let index = this.uploadedFileList.indexOf(file);
        //从列表中移除该文件
        this.uploadedFileList.splice(index, 1);
        return true;
      },
      // 自定义options
      customizeOptions(opts) {
        // 自定义文件上传类型
        let accept = opts.accept || ACCEPT_CONFIG.getAll()
        this.acceptType = accept.join()
      },

      /**
       * 计算md5值，以实现断点续传及秒传
       * @param file
       * @returns Promise
       */
      computeMD5(file) {
        let fileReader = new FileReader()
        let time = new Date().getTime()
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
        let currentChunk = 0
        const chunkSize = 10 * 1024 * 1000
        let chunks = Math.ceil(file.size / chunkSize)
        let spark = new SparkMD5.ArrayBuffer()

        // 文件状态设为"计算MD5"
        // this.statusSet(file.id, 'md5')
        // file.pause()
        loadNext()

        return new Promise((resolve, reject) => {
          fileReader.onload = (e) => {
            spark.append(e.target.result)

            if (currentChunk < chunks) {
              currentChunk++
               loadNext()
              // 实时展示MD5的计算进度
              this.$nextTick(() => {
                const md5ProgressText = '校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%'
                console.log("MD5",md5ProgressText)
                // document.querySelector(`.custom-status-${file.id}`).innerText = md5ProgressText
              })

            } else {
              let md5 = spark.end()

              // md5计算完毕
              resolve({
                md5,
                file
              })

              console.log(
                `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${
                new Date().getTime() - time
              } ms`
              )
            }
          }

          fileReader.onerror = function() {
            this.error(`文件${file.name}读取出错，请检查该文件`)
            file.cancel()
            reject()
          }
        })

        function loadNext() {
          let start = currentChunk * chunkSize
          let end = start + chunkSize >= file.size ? file.size : start + chunkSize

          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
        }
      },

      // md5计算完毕，开始上传
      startUpload({
        md5,
        file,
        onSuccess,
        onError,
        onProgress
      }) {
        var that = this;
        file.uniqueIdentifier = md5
        // file.resume()
        // this.statusRemove(file.id)
        // 从服务端获取上传所需的一些必要信息
        getUploadRequiredInfo().then((res) => {
          if (res.code !== 0) {
            this.$notification.error({
              message: '获取失败',
              description: res.msg
            })
            return
          }
        
          var resourceKey = res.data.key + file.name.substring(file.name.lastIndexOf('.'))
          var cos = this.getCOS()
          // 直接调用cos sdk的方法进行上传
          cos.uploadFile({
            Region: res.data.region,
            Bucket: res.data.bucket,
            Key: resourceKey,
            Body: file,
            SliceSize: this.uploadSliceSize,
            Method: 'PUT',
            onTaskReady: function(taskId) {
              // console.log("onTaskReady", taskId)
            },
            onProgress: function(progress) {
              file.percent = progress.percent
              onProgress({ percent:progress.percent });
            },
            onFileFinish: function(err, data, options) {
              if (err) {
                onError(err, options)
              }
              onSuccess()
              var para = {
                key: resourceKey,
                md5: file.uniqueIdentifier,
                filename: file.name
              }
              if (that.customParams.page == "video") {
                addVideo(para)
                  .then((res) => {
                    if (res.code !== 0) {
                      that.$message.error(res.msg)
                      return
                    }
        
                  })
              }
              that.emit('fileSuccess', para)
        
            }
          })
        })
        
      },

      onFileProgress(rootFile, file, chunk) {
        console.log(
          `上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${
          chunk.endByte / 1024 / 1024
        }`
        )
      },

      onFileError(rootFile, file, response, chunk) {
        this.error(response)
      },

      close() {
        if(this.uploadedFileList.length>0){
            this.$message.info("文件上传结束后将自动关闭面板")
        }else{
          this.panelShow = false
        }
      },

      /**
       * 新增的自定义的状态: 'md5'、'merging'、'transcoding'、'failed'
       * @param id
       * @param status
       */
      statusSet(id, status) {
        let statusMap = {
          md5: {
            text: '校验MD5',
            bgc: '#fff'
          },
          merging: {
            text: '合并中',
            bgc: '#e2eeff'
          },
          transcoding: {
            text: '转码中',
            bgc: '#e2eeff'
          },
          failed: {
            text: '上传失败',
            bgc: '#e2eeff'
          }
        }

        this.customStatus = status
        this.$nextTick(() => {
          const statusTag = document.createElement('p')
          // statusTag.className = `custom-status-${id} custom-status`
          // statusTag.innerText = statusMap[status].text
          // statusTag.style.backgroundColor = statusMap[status].bgc

          // const statusWrap = document.querySelector(`.file_${id} .uploader-file-status`)
          // statusWrap.appendChild(statusTag)
        })
      },

      statusRemove(id) {
        this.customStatus = ''
        this.$nextTick(() => {
          const statusTag = document.querySelector(`.custom-status-${id}`)
          statusTag.remove()
        })
      },

      emit(e, para) {
        Bus.$emit(e, para)
        this.$emit(e, para)
      },

      error(msg) {
        this.$notify({
          title: '错误',
          message: msg,
          type: 'error',
          duration: 2000
        })
      }
    }
  }
</script>

<style lang="less">
  #global-uploader {
    &:not(.global-uploader-single) {
      position: fixed;
      z-index: 20;
      right: 15px;
      bottom: 15px;
      box-sizing: border-box;
    }

    .uploader-app {
      width: 520px;
    }

    .file-panel {
      background-color: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 7px 7px 0 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 400px;

      .file-title {
        display: flex;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        border-bottom: 1px solid #ddd;

        .operate {
          flex: 1;
          text-align: right;

          i {
            font-size: 18px;
          }
        }
      }

      .file-list {
        position: relative;
        height: 240px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #fff;
        transition: all 0.3s;

        .file-item {
          background-color: #fff;
        }
      }

      &.collapse {
        .file-title {
          background-color: #e7ecf2;
        }

        .file-list {
          height: 0;
        }
      }
    }

    .no-file {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }

    .uploader-file {
      &.md5 {
        .uploader-file-resume {
          display: none;
        }
      }
    }

    .uploader-file-icon {
      &:before {
        content: '' !important;
      }

      &[icon='image'] {
        background: url(./images/image-icon.png);
      }

      &[icon=audio] {
        background: url(./images/audio-icon.png);
        background-size: contain;
      }

      &[icon='video'] {
        background: url(./images/video-icon.png);
      }

      &[icon='document'] {
        background: url(./images/text-icon.png);
      }

      &[icon=unknown] {
        background: url(./images/zip.png) no-repeat center;
        background-size: contain;
      }
    }

    .uploader-file-actions>span {
      margin-right: 6px;
    }

    .custom-status {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  /* 隐藏上传按钮 */
  #global-uploader-btn {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  .global-uploader-single {
    #global-uploader-btn {
      position: relative;
    }
  }
</style>
