export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

/**
 * 秒 转文本显示 x时y分z秒
 * @param {} secs
 */
export function secondsToTime (secs) {
  const hoursDiv = secs / 3600
  // 向下取整
  let hours = Math.floor(hoursDiv)

  const minutesDiv = (secs % 3600) / 60
  let minutes = Math.floor(minutesDiv)

  // 向上取整
  let seconds = Math.ceil((secs % 3600) % 60)
  if (seconds > 59) {
    seconds = 0
    minutes = Math.ceil(minutesDiv)
  }

  if (minutes > 59) {
    minutes = 0
    hours = Math.ceil(hoursDiv)
  }

  let timeStr = ''

  if (hours > 0) {
    timeStr += `${hours}时`
  }
  if (minutes > 0) {
    timeStr += `${minutes}分`
  }
  if (seconds >= 0) {
    timeStr += `${seconds}秒`
  }
  return timeStr
}

/**
 * 秒 转文本显示 x时y分z秒
 * @param {} secs
 */
export function secondsFormat (value) {
  let secondTime = parseInt(value)
    let minuteTime = 0
    let hourTime = 0
    if (secondTime >= 60) {
      minuteTime = parseInt(secondTime / 60)
      secondTime = parseInt(secondTime % 60)
      if (minuteTime >= 60) {
        hourTime = parseInt(minuteTime / 60)
        minuteTime = parseInt(minuteTime % 60)
      }
    }
    // 补0
    hourTime = hourTime < 10 ? '0' + hourTime : hourTime
    minuteTime = minuteTime < 10 ? '0' + minuteTime : minuteTime
    secondTime = secondTime < 10 ? '0' + secondTime : secondTime
    const res = hourTime + ':' + minuteTime + ':' + secondTime
    return res
}

/**
 * 字节大小转显示文本
 * @param {} size
 */
export function byteToText (size) {
  const mb = Math.floor(size / (1024 * 1024))
  const kb = Math.floor(size / 1024)

  if (mb > 0) {
    return (size / (1024 * 1024)).toFixed(2) + 'MB'
  } else if (kb > 0) {
    return (size / 1024).toFixed(2) + 'KB'
  } else {
    return size.toFixed(2) + 'B'
  }
}
