<template>
  <pro-layout :menus="menus" :collapsed="collapsed" :mediaQuery="query" :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery" :handleCollapse="handleCollapse" :i18nRender="i18nRender" v-bind="settings">
    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div>
        <img src="@/assets/logo.svg" />
        <h1>{{ title }}</h1>
      </div>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
    <template v-slot:headerContentRender>
      <div>
        <a-tooltip title="进入全屏" v-if="isFullScreen==false">
          <Icon type="md-expand" style="font-size: 22px;cursor: pointer;" @click="changeFullScreenStatus" />
        </a-tooltip>
        <a-tooltip title="退出全屏" v-else>
          <Icon type="md-contract" style="font-size: 22px;cursor: pointer;" @click="changeFullScreenStatus" />
        </a-tooltip>
      </div>
    </template>

    <setting-drawer v-if="isDev" :settings="settings" @change="handleSettingChange">

    </setting-drawer>
    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'sidemenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <!-- custom footer / 自定义Footer -->
    <template v-slot:footerRender>
      <global-footer />
    </template>
    <router-view />
  </pro-layout>
</template>

<script>
  import {
    SettingDrawer,
    updateTheme
  } from '@ant-design-vue/pro-layout'
  // import { SettingDrawer } from '@/components/SettingDrawer/SettingDrawer'
  import {
    i18nRender
  } from '@/locales'
  import {
    mapState
  } from 'vuex'
  import {
    CONTENT_WIDTH_TYPE,
    SIDEBAR_TYPE,
    TOGGLE_MOBILE_TYPE
  } from '@/store/mutation-types'

  import defaultSettings from '@/config/defaultSettings'
  import RightContent from '@/components/GlobalHeader/RightContent'
  import GlobalFooter from '@/components/GlobalFooter'

  export default {
    name: 'BasicLayout',
    components: {
      SettingDrawer,
      RightContent,
      GlobalFooter
    },
    data() {
      return {
        // preview.pro.antdv.com only use.
        isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
        // end
        isDev: process.env.NODE_ENV === 'development' || process.env.VUE_APP_PREVIEW === 'true',

        // base
        menus: [],
        // 侧栏收起状态
        collapsed: false,
        isFullScreen: false,
        title: defaultSettings.title,
        settings: {
          // 布局类型
          layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
          // CONTENT_WIDTH_TYPE
          contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
          // 主题 'dark' | 'light'
          theme: defaultSettings.navTheme,
          // 主色调
          primaryColor: defaultSettings.primaryColor,
          fixedHeader: defaultSettings.fixedHeader,
          fixSiderbar: defaultSettings.fixSiderbar,
          colorWeak: defaultSettings.colorWeak,

          hideHintAlert: false,
          hideCopyButton: false
        },
        // 媒体查询
        query: {},

        // 是否手机模式
        isMobile: false,
      }
    },
    computed: {
      ...mapState({
        // 动态主路由
        mainMenu: state => state.permission.addRouters
      })
    },
    created() {
      const routes = this.mainMenu.find(item => item.path === '/')
      this.menus = (routes && routes.children) || []
      console.log("BasicLayout", this.menus)
      // 处理侧栏收起状态
      this.$watch('collapsed', () => {
        this.$store.commit(SIDEBAR_TYPE, this.collapsed)
      })
      this.$watch('isMobile', () => {
        this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
      })
    },
    mounted() {
      const userAgent = navigator.userAgent
      if (userAgent.indexOf('Edge') > -1) {
        this.$nextTick(() => {
          this.collapsed = !this.collapsed
          setTimeout(() => {
            this.collapsed = !this.collapsed
          }, 16)
        })
      }

      // first update color
      // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
      if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
        updateTheme(this.settings.primaryColor)
      }
    },
    methods: {
      i18nRender,
      handleMediaQuery(val) {
        this.query = val
        if (this.isMobile && !val['screen-xs']) {
          this.isMobile = false
          return
        }
        if (!this.isMobile && val['screen-xs']) {
          this.isMobile = true
          this.collapsed = false
          this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          // this.settings.fixSiderbar = false
        }
      },
      handleCollapse(val) {
        this.collapsed = val
      },
      changeFullScreenStatus() {

        // 判断全屏状态的变量
        this.isFullScreen = !this.isFullScreen
        const element = document.documentElement
        if (this.isFullScreen==false) {
          // 如果浏览器有这个Function
          if (document.exitFullscreen) {
            document.exitFullscreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
          }
        } else {
          // 如果浏览器有这个Function
          if (element.requestFullscreen) {
            element.requestFullscreen()
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen()
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen()
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen()
          }
        }

      },

      handleSettingChange({
        type,
        value
      }) {
        console.log('type', type, value)
        type && (this.settings[type] = value)
        switch (type) {
          case 'contentWidth':
            this.settings[type] = value
            break
          case 'layout':
            if (value === 'sidemenu') {
              this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
            } else {
              this.settings.fixSiderbar = false
              this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
            }
            break
        }

      }
    }
  }
</script>

<style lang="less">
  @import "./BasicLayout.less";

  .ant-pro-global-header-content {
    line-height: 60px;
  }
</style>
