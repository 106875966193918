import request from '@/utils/request'

const baseApi = {
  GetPicCaptcha: '/base/getCaptcha',
  Login: '/base/login',
  LoginByPhone: '/base/loginByPhone',
  GetPhoneCaptcha: '/base/getPhoneCaptcha',
  Register: '/base/register'
}

const userApi = {
  Login: '/auth/login',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info',
  UserMenu: '/user/nav'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request({
    url: baseApi.Login,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function loginByPhone (parameter) {
  return request({
    url: baseApi.LoginByPhone,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function register (parameter) {
  return request({
    url: baseApi.Register,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getPicCaptcha (parameter) {
  return request({
    url: baseApi.GetPicCaptcha,
    method: 'post',
    data: parameter
  })
}

export function getPhoneCaptcha (parameter) {
  return request({
    url: baseApi.GetPhoneCaptcha,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav () {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return request({
    url: userApi.twoStepCode,
    method: 'post',
    data: parameter
  })
}
// /////////////////////////// 新接口  //////////////////////////////////

/**
 * 用户注册(暂时关闭)
 * @param {Object} parameter
 * username  string Y 用户登录名，全局唯一。
  * password string  Y 用户登录密码。
  *                  N 用户真实姓名。
  *                 Y 用户登录手机号。
  * companyName string N 公司名称。  * headerImg string N 用户头像在oss上的key值。key的获取和文件的上传参考视频文件 上传的过程。  * email string Y 用户邮箱。   *               用户注册手机验证码。获取方式参考【1.2 获取注册或手机号登录 时的手机验证码】。
 */
export function userRegister (parameter) {
  return request({
    url: "/user/base/register",
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 获取账号密码登录时的图片验证码
 * @param {Object} 
 */
export function sendPicCaptcha () {
  return request({
    url: "/user/base/getCaptcha",
    method: 'get',
  })
}
/**
 * 发送手机验证码
 * @param {Object} parameter
 *  phone Y 手机号。
 *  type 验证码类型:Login-登录，Register-注册，默认为Login。 
 */
export function sendPhoneCaptcha (parameter) {
  return request({
    url: "/user/base/sendPhoneCaptcha",
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 账号密码方式登录
 * @param {Object} parameter
 *  username Y 用户登录名。。
 *  password Y  用户登录密码。 
 *  captcha Y 图片验证码。
 * captchaId  Y 图片验证码ID。
 */
export function userLogin (parameter) {
  return request({
    url: "/user/base/login",
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 手机号验证码方式登录
 * @param {Object} parameter
 *  phone Y 用户登录名。。
 *  phoneCaptcha Y 手机验证码。。
 */
export function userPhoneLogin (parameter) {
  return request({
    url: "/user/base/loginByPhone",
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 1.6 修改当前登录用户的密码
 * @param {Object} parameter
 *  password Y 原密码。
 *  newPassword Y 新密码。
 */
export function changeUserPassword (parameter) {
  return request({
    url: "/user/base/changePassword",
    method: 'put',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 1.7 退出登录
 * @param {Object} parameter
 *  password Y 原密码。
 *  newPassword Y 新密码。
 */
export function userLogout (parameter) {
  return request({
    url: "/user/base/logout",
    method: 'put',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}



// //////////////////////////////////////////////////////////////////////////////

const managerApi = {
  Logout: '/manager/logout',
  ChangePassword: '/manager/changePassword'
}

export function logout () {
  return request({
    url: managerApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function changePassword () {
  return request({
    url: managerApi.ChangePassword,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
