import request from '@/utils/request'

const contentApi = {
  GetVideoList: '/content/video/getVideoList',
  AddVideo: '/content/video/addVideo',
  DeleteVideo: '/content/video/deleteVideo',
  UpdateVideo: '/content/video/updateVideo'
}

export function getVideoList (parameter) {
  return request({
    url: contentApi.GetVideoList,
    method: 'get',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function addVideo (parameter) {
  return request({
    url: contentApi.AddVideo,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除视频
 * @param {Object} parameter
 * ids 视频ID列表。
 */
export function deleteVideo (parameter) {
  return request({
    url: contentApi.DeleteVideo,
    method: 'delete',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function updateVideo (parameter) {
  return request({
    url: contentApi.UpdateVideo,
    method: 'put',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * @param {Object} parameter
 * name 直播名称，传入后会进行模糊匹配。
 * page ⻚码，有效范围:[1, 总⻚数)，默认为1。
 * pageSize 每⻚数据条数，有效范围:[5, 20]，默认为10
 */
export function getStreamList (parameter) {
  return request({
    url: '/content/livestream/getLivestreamList',
    method: 'get',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * @param {Object} parameter
 * name 直播名称，⻓度限制:50个汉字/英文字母/数字。
 * description 直播描述。
 * streamUrl 直播流地址。
 */
export function addStream (parameter) {
  return request({
    url: '/content/livestream/addLivestream',
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * @param {Object} parameter
 * ids  直播ID列表。
 */

export function deleteStream (parameter) {
  return request({
    url: '/content/livestream/deleteLivestream',
    method: 'delete',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function updateStream (parameter) {
  return request({
    url: '/content/livestream/updateLivestream',
    method: 'put',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 获取视频集列表
 * @param {Object} parameter
 * name  视频集名称，传入后会进行模糊匹配。
 * page ⻚码，有效范围:[1, 总⻚数)，默认为1。
 * pageSize 每⻚数据条数，有效范围:[5, 20]，默认为10。
 */
export function getVideoSetList (parameter) {
  return request({
    url: '/content/videoSet/getVideoSetList',
    method: 'get',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 创建视频集
 * @param {Object} parameter
 * name 视频集名称，⻓度限制:50个汉字/英文字母/数字。
 */
export function addVideoSet (parameter) {
  return request({
    url: '/content/videoSet/addVideoSet',
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 更新视频集基础信息
 * @param {Object} parameter
 * id 视频集ID。
 * name 视频集名称，⻓度限制:50个汉字/英文字母/数字。
 */
export function updateVideoSet (parameter) {
  return request({
    url: '/content/videoSet/updateVideoSet',
    method: 'put',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 删除视频集
 * @param {Object} parameter
 * ids 视频集ID列表。
 */
export function deleteVideoSet (parameter) {
  return request({
    url: '/content/videoSet/deleteVideoSet',
    method: 'delete',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取视频集包含的视频列表
 * @param {Object} parameter
 * videoSetId 视频集ID。
 */
export function getVideoSetVideos (parameter) {
  return request({
    url: '/content/videoSet/getVideoSetVideos',
    method: 'get',
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 批量将视频加入视频集中
 * @param {Object} parameter
 * videoSetId  视频集ID。
 * videoIds  要加入视频集的视频ID列表，服务端会跳过已经加入过的视频，并 在该请求的响应内容中体现出来。
 */
export function addVideoSetVideos (parameter) {
  return request({
    url: '/content/videoSet/addVideoSetVideos',
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 批量将视频集中视频 删除
 * @param {Object} parameter
 * videoSetId  视频集ID。
 * videoIds  要从视频集中删除的视频ID列表。
 */
export function deleteVideoSetVideos (parameter) {
  return request({
    url: '/content/videoSet/deleteVideoSetVideos',
    method: 'delete',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 调整视频集中指定视频的顺序
 * @param {Object} parameter
 * videoSetId   视频集ID。
 * targetVideoId  要调整排序位置的视频ID。
 * coveredVideoId  将要被替换位置的视频ID。
 */
export function reorderVideoSetVideo (parameter) {
  return request({
    url: '/content/videoSet/reorderVideoSetVideo',
    method: 'put',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
